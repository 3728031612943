const firebaseConfig = {
  apiKey: 'AIzaSyAzXT4FHB1kWd_jGkkLL1zdw-II7ryke3Y',
  authDomain: 'cloudyug-f2fe4.firebaseapp.com',
  projectId: 'cloudyug-f2fe4',
  storageBucket: 'cloudyug-f2fe4.appspot.com',
  messagingSenderId: '840976261947',
  appId: '1:840976261947:web:93f2d017585a79110a4d1e',
  measurementId: 'G-H2Q6XRM635',
}

export default firebaseConfig
